/**
 * Tomas Costantino
 *
 * Judge screen score post
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function updateScore(judge_id, match_id, payload) {
    return makeAuthenticatedRequest('PATCH', `/judge/${judge_id}/match/${match_id}/score`, payload);
}
