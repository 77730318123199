/**
 * Tomas Costantino
 *
 * Server module for tournament settings
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";
import axios from "axios";

export async function getTournaments() {
    return makeAuthenticatedRequest('GET', '/tournament');
}

export async function getTournamentsByUserId(userId) {
    return makeAuthenticatedRequest('GET', `/tournament/by-user-id/${userId}`);
}

export async function getFinishedTournaments(userId) {
    return makeAuthenticatedRequest('GET', `/tournament/finished/by-user-id/${userId}`);
}

export async function getTournamentDrawById(tournamentId) {
    return makeAuthenticatedRequest('GET', `/tournament/by-tournament-id/${tournamentId}/draw`);
}

export async function getTournamentDetails(tournamentId) {
    return makeAuthenticatedRequest('GET', `/tournament/by-tournament-id/${tournamentId}/details`);
}

export async function getAvailableTournamentsForUserId(userId) {
    return makeAuthenticatedRequest('GET', `/tournament/available/by-user-id/${userId}`);
}

export async function getTournamentsForAdmin() {
    return makeAuthenticatedRequest('GET', `/tournament/admin`);
}

export async function getTournamentDrawForAdmin(tournamentId) {
    return makeAuthenticatedRequest('GET', `/tournament/by-tournament-id/${tournamentId}/draw/admin`);
}

export async function getTournamentExportData(tournamentId) {
    const token = btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`);
    return axios.get(`${process.env.REACT_APP_API_BASE}/tournament/by-tournament-id/${tournamentId}/export`, {
        responseType: 'blob',
        headers: {
            'Accept': 'application/zip',
            'Authorization': `Basic ${token}`,
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw new Error(error.response?.data?.detail || 'Download failed');
        });
}
