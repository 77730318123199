/**
 *  Tomas Costantino
 *
 *  Tie breaker
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function getTies() {
    return makeAuthenticatedRequest('GET', '/ties');
}

export async function submitTieBreaker(data) {
    return makeAuthenticatedRequest('POST', '/ties', data);
}
