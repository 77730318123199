/*
 * Tomas Costantino
 *
 * Retrieve categories belts and key_data with belt
 * */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function getKeyData() {
    return makeAuthenticatedRequest('GET', '/key-data');
}
