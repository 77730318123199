/*
 * Tomas Costantino
 *
 * Post new tournament
 * */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function postTournament(payload) {
    return makeAuthenticatedRequest('POST', '/tournament', payload);
}

export async function enrollToTournament(payload) {
    return makeAuthenticatedRequest('POST', '/tournament/enroll', payload);
}

export async function confirmEnrollToTournament(payload) {
    return makeAuthenticatedRequest('POST', '/tournament/enroll/confirmation', payload);
}
