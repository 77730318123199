import axios from 'axios';

export async function makeAuthenticatedRequest(method, path, payload = null) {
    const token = btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`);

    const fullPath = path.startsWith('/') ? path : `/${path}`;

    const config = {
        method: method.toLowerCase(),
        url: `${process.env.REACT_APP_API_BASE}${fullPath}`,
        headers: {
            'Authorization': `Basic ${token}`,
        }
    };

    if (payload && (method.toLowerCase() === 'post' || method.toLowerCase() === 'put' || method.toLowerCase() === 'patch')) {
        config.data = payload;
    }

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (error.response?.status === 401) {
            localStorage.clear();
        }
        throw new Error(error.response?.data?.detail || error.message);
    }
}
