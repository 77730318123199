/**
 * Tomas Costantino
 *
 * Judge screen score get
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function getUpcomingMatches(tournamentId) {
    return makeAuthenticatedRequest('GET', `/match/upcoming/by-tournament-id/${tournamentId}`);
}

export async function getJudgeScores(tournamentId) {
    return makeAuthenticatedRequest('GET', `/judge/scores/by-tournament-id/${tournamentId}`);
}

export async function getMatchScores(tournamentId) {
    return makeAuthenticatedRequest('GET', `/match/scores/by-tournament-id/${tournamentId}`);
}
