/**
 * Tomas Costantino
 *
 * Reset password server interaction
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export function resetPassword(data) {
    return makeAuthenticatedRequest('PATCH', '/reset-password', data);
}
