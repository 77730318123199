/*
 * Tomas Costantino
 *
 * Handle authentication with server
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function postLogin(payload) {
    try {
        return await makeAuthenticatedRequest('POST', '/login', payload);
    } catch (error) {
        localStorage.clear();
        throw error;
    }
}
