/*
 * Tomas Costantino
 *
 * Handle sign up with server
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";
import axios from "axios";

export async function signUpUser(data) {
    try {
        return await makeAuthenticatedRequest('POST', '/user', data);
    } catch (error) {
        localStorage.clear();
        throw error;
    }
}

export async function postUserPhoto(data) {
    const token = btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`);
    return axios.post(`${process.env.REACT_APP_API_BASE}/user/photo`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Basic ${token}`,
        },
    });
}
