/**
 * Tomas Costantino
 *
 * Server module for match scores
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function getMatchesForTournamentIdByUserId(userId, tournamentId) {
    return makeAuthenticatedRequest('GET', `/match/${userId}/${tournamentId}`);
}

export async function swapMatchParticipants(payload) {
    return makeAuthenticatedRequest('POST', '/match/swap', payload);
}

export async function getTournamentMatchesByTournament(tournamentId) {
    return makeAuthenticatedRequest('GET', `/tournament/${tournamentId}/matches`);
}
