/**
 * Tomas Costantino
 *
 * Retrieve user user data from server
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function getUser(userId) {
    return makeAuthenticatedRequest('GET', `/user/${userId}`);
}

export async function getUsers() {
    return makeAuthenticatedRequest('GET', '/user');
}

export async function getUserPermissions() {
    return makeAuthenticatedRequest('GET', '/user-permissions');
}

export async function getProfilePhoto(userId) {
    return makeAuthenticatedRequest('GET', `/user/${userId}/photo`);
}
