/**
 * Tomas Costantino
 *
 * Server module for tournament settings
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function updateTournament(tournamentId, payload) {
    return makeAuthenticatedRequest('PATCH', `/tournament/by-tournament-id/${tournamentId}`, payload);
}

export async function updateTournamentStatus(tournamentId) {
    return makeAuthenticatedRequest('PATCH', '/tournament/status', { tournament: tournamentId });
}

export async function updateTournamentDraw(tournamentId, groups, participants, availablePlayers, judges, qualifiers) {
    return makeAuthenticatedRequest('PATCH', `/tournament/by-tournament-id/${tournamentId}/draw`, {
        groups: groups,
        available: availablePlayers,
        round: 1,
        judges: judges,
        participants: participants,
        qualifiers: qualifiers || false
    });
}
