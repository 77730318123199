import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UCard } from "../CustomComponents/UCard";
import { ULoading } from "../CustomComponents/ULoading";
import { ThemeLanguageToggle } from "../CustomComponents/ThemeLanguageToggle";
import { postLogin } from "../server/login/[...].login.post";
import { saveToLocalStorage } from "../utils/localStorageHandler";
import { useLanguage } from '../contexts/LanguageContext';

const translations = {
    en: {
        login: "Login",
        nickname: "Nickname",
        password: "Password",
        signUp: "Sign Up",
        switchTo: "Switch to Portuguese",
        resetPassword: "Reset Password",
        userLoggedIn: "There is already an user logged in this browser, log out first",
        noInternetError: "No internet connection. Unable to authenticate.",
        wrongPasswordError: "Wrong password",
        nicknameNotFound: "Nickname does not exist in database",
        noAuth: "Wrong password or nickname is not registered",
        serverConnectionError: "Cannot connect to the server",
        serverError: "Server error, contact Flecha",
        welcome: "Welcome to Abada Capoeira"
    },
    pt: {
        login: "Entrar",
        nickname: "Apelido",
        password: "Senha",
        signUp: "Registrar-se",
        switchTo: "Mudar para Inglês",
        resetPassword: "Redefinir Senha",
        userLoggedIn: "Já existe um usuário logado neste navegador, saia primeiro",
        noInternetError: "Sem conexão com a internet. Incapaz de autenticar.",
        wrongPasswordError: "Senha incorreta",
        nicknameNotFound: "O apelido não existe no banco de dados",
        noAuth: "Senha ou apelido errado não está registrado",
        serverConnectionError: "Não é possível conectar ao servidor",
        serverError: "Erro de servidor, contactar Flecha",
        welcome: "Bem-vindo ao Abada Capoeira"
    }
};

export const Login = () => {
    const [nickname, setNickname] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { language } = useLanguage();
    const t = translations[language];

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const response = await postLogin({nickname: nickname, password: password});
            saveToLocalStorage('userId', response.user_id);
            saveToLocalStorage('userPermissions', response.user_type);
            saveToLocalStorage('authToken', true);
            navigate('/profile');
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center">
            <h1 className="text-xl font-bold text-primary mb-2">{t.welcome}</h1>
            <div className="w-full max-w-md">
                <UCard
                    title={t.login}
                    headerClass="text-center"
                    bodyClass="space-y-4"
                    footerClass="text-center"
                    footer={
                        <a href="/reset-password" className="text-body text-primary hover:text-primary-dark">
                            {t.resetPassword}
                        </a>
                    }
                >
                    <div className="absolute top-5 right-5 space-x-2 flex items-center">
                        <ThemeLanguageToggle />
                    </div>
                    <form onSubmit={handleLogin} className="space-y-4">
                        <input
                            type="text"
                            placeholder={t.nickname}
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            className="input w-full"
                            required
                            disabled={isLoading}
                        />
                        <input
                            type="password"
                            placeholder={t.password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input w-full"
                            required
                            disabled={isLoading}
                        />
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                        <button
                            type="submit"
                            className="btn btn-primary w-full flex justify-center items-center"
                            disabled={isLoading}
                        >
                            {t.login}
                        </button>
                    </form>
                    <button
                        onClick={() => navigate('/signup')}
                        className="btn btn-secondary w-full"
                        disabled={isLoading}
                    >
                        {t.signUp}
                    </button>
                    {isLoading && <ULoading />}
                </UCard>
            </div>
        </div>
    );
};
