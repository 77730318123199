/**
 * Tomas Costantino
 *
 * Judge screen score post
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function getJudgeTournaments(user_id) {
    return makeAuthenticatedRequest('GET', `/judge/${user_id}/tournament`);
}

export async function getJudgeMatchesForTournament(user_id, tournament_id) {
    return makeAuthenticatedRequest('GET', `/judge/${user_id}/matches/${tournament_id}`);
}
