/**
 * Tomas Costantino
 *
 * Server module for tournament settings
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function deleteTournament(tournamentId) {
    return makeAuthenticatedRequest('DELETE', `/tournament/by-tournament-id/${tournamentId}`);
}
