/**
 * Tomas Costantino
 *
 * Reset terms and conditions server interaction
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export function updateTermsAndConditions(data) {
    return makeAuthenticatedRequest('POST', '/terms-and-conditions', { template: data });
}

export function getTermsAndConditions() {
    return makeAuthenticatedRequest('GET', '/terms-and-conditions');
}

