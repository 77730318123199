/**
 * Tomas Costantino
 *
 * Deactivate user
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function deleteUser(userId) {
    return makeAuthenticatedRequest('DELETE', `/user/${userId}`);
}
