/**
 * Tomas Costantino
 *
 * Retrieve user user data from server
 */

import { makeAuthenticatedRequest } from "../authenticatedRequest";

export async function patchUser(userId, payload) {
    return makeAuthenticatedRequest('PATCH', `/user/${userId}`, payload);
}

export async function patchUserPermissions(payload) {
    return makeAuthenticatedRequest('PATCH', '/user-permissions', payload);
}

export async function reactivateUser(userId) {
    return makeAuthenticatedRequest('PATCH', `/user/${userId}/reactivate`);
}
